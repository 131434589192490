<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->

    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-title>
              <h3>
                المادة
              </h3>
            </b-card-title>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <h4>الدخول</h4>
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in itemsOptions"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="5">
                          <label>
                            المادة
                          </label>
                        </b-col>
                        <b-col cols="12" lg="3">
                          <label>
                            الكمية
                          </label>
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label>
                            الواحدة
                          </label>
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="5">
                          <label class="d-inline d-lg-none">المادة</label>
                          <v-select
                            v-model="item.itemTitle"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="itemTitle"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="(val) => updateItemForm(index, val)"
                          />
                        </b-col>
                        <b-col cols="12" lg="3">
                          <label class="d-inline d-lg-none">الكمية</label>
                          <b-form-input
                            v-model="item.cost"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">الواحدة</label>
                          <b-form-input
                            v-model="item.qty"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>

            <!-- Invoice Description: Total -->

            <!-- Spacer -->

            <hr class="invoice-spacing" />
            <b-card-body class="invoice-padding form-item-section">
              <h4>الخروج</h4>
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in itemsOptions"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="5">
                          <label>
                            المادة
                          </label>
                        </b-col>
                        <b-col cols="12" lg="3">
                          <label>
                            الكمية
                          </label>
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label>
                            الواحدة
                          </label>
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="5">
                          <label class="d-inline d-lg-none">المادة</label>
                          <v-select
                            v-model="item.itemTitle"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="itemTitle"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="(val) => updateItemForm(index, val)"
                          />
                        </b-col>
                        <b-col cols="12" lg="3">
                          <label class="d-inline d-lg-none">الكمية</label>
                          <b-form-input
                            v-model="item.cost"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">الواحدة</label>
                          <b-form-input
                            v-model="item.qty"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
    </b-row>

    <!-- <invoice-sidebar-send-invoice />
      <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import router from "@/router";
import {
  bCardTitle,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import financeStoreModule from "./financeStoreModule.js";

export default {
  components: {
    BRow,
    bCardTitle,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    "invoiceData.items": function() {
      this.initTrHeight();
    },
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0);
      });
    },
  },
  setup() {
    const { route } = useRouter();
    const id = route.value.params.id;
    const FINANCE_APP_STORE_MODULE_NAME = "app-finance";

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME))
      store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME))
        store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME);
    });
    store.dispatch("app-finance/GetItemRequest", { id }).then((response) => {
     //console.log("response",response)
    })
   
    const invoiceData = ref(null);
    const paymentDetails = ref({});

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: "",
    };

    const itemsOptions = [
      {
        itemTitle: "",
        cost: 24,
        qty: 1,
        description: "Designed UI kit & app pages.",
      },
    ];

    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val;
      invoiceData.value.items[index].cost = cost;
      invoiceData.value.items[index].qty = qty;
      invoiceData.value.items[index].description = description;
    };

    const paymentMethods = ["Bank Account", "PayPal", "UPI Transfer"];

    return {
      invoiceData,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.form-item-action-col {
  width: 27px;
}
b-card-title {
  text-align: center;
  margin-top: 12px;
}
.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}
.border-Primary.mb-10.col-md-12.col-xl-4 {
  margin-bottom: 21px;
}
.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
